import React, { useEffect, useState } from "react";
import classes from "./ServicesSection.module.css";
import ServiceContent from "./ServiceContent";
import ServiceSelector from "./ServiceSelector";

const SERVICES = [
  {
    id: "1",
    service: "Market Entry Evaluation",
    description:
      "We conduct market strategy for each product by assessing product-market fit considering market trends, competitors, price points, local consumer behaviour, market demand, and supply.",
  },

  {
    id: "2",
    service: "Registration & Compliances",
    description:
      "We provide import codes and product registration in compliance with government regulations and industry standards of GCC countries.",
  },

  {
    id: "3",
    service: "Offline Sales",
    description:
      "Our approach is focused on ensuring products reaches their full potential in the market by placing and promoting them on relevant offline sales channels including retail stores, groceries, pharmacies, hotels, restaurants, cafes, hospital, schools, and other institutions.",
  },

  {
    id: "4",
    service: "Online Sales",
    description:
      "From creating an effective listing to running efficient marketing campaigns, at Modist we are experts in driving online visibility leading to brand building, engagement, and ultimately sales.",
  },

  {
    id: "5",
    service: "Local Logistics",
    description:
      "As a pioneer distributor in the MENA region, our success is backed up by our local logistics capabilities. We have 5+ warehouses and 100+ vehicles which distribute our portfolio products to thousands of stores regularly.",
  },

  {
    id: "6",
    service: "Real-Time Data Analytics",
    description:
      "Monitor the online and offline sales along with inventory levels in real-time through our centralized dashboards across the sale channels. These insights give visibility into the growth and penetration of the brand while giving actionable insights for strategic decision-making.",
  },
];

function ServicesSection() {
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [shouldAutoChange, setShouldAutoChange] = useState(true); //based on manual selection of a service

  // to autoscroll in order through services listed in ServiceSelector component
  useEffect(() => {
    const interval = setInterval(() => {
      if (shouldAutoChange) {
        // true initially (on component mount)
        setIsVisible(false); // Start the exit animation
        setTimeout(() => {
          setCurrentServiceIndex(
            (prevIndex) => (prevIndex + 1) % SERVICES.length
          );
          setIsVisible(true); // Start the entry animation (slideIn)
        }, 200); // time matched to animation duration
      }
    }, 4000); // switch to next service in 4 secs

    return () => {
      clearInterval(interval);
    };
  }, [shouldAutoChange]);

  // if a user manually selects a service from ServiceSelector component
  function handleServiceSelect(index) {
    setShouldAutoChange(false); // Disable auto change for now
    setIsVisible(false); // Start the exit animation
    setTimeout(() => {
      setCurrentServiceIndex(index);
      setIsVisible(true); // Start the entry animation
      setTimeout(() => {
        setShouldAutoChange(true); // Enable auto change after 4 secs
      }, 4000);
    }, 200); // time to matched to animation duration
  }

  return (
    <div id="homeThirdSection" className={classes.servicesSection}>
      <h2 className={classes.smallHeader}>HOW WE WORK</h2>
      <div className={classes.servicesWrapper}>
        <ServiceContent
          isVisible={isVisible}
          service={SERVICES[currentServiceIndex].service}
          description={SERVICES[currentServiceIndex].description}
        />
        <ServiceSelector
          index={currentServiceIndex}
          onSelect={handleServiceSelect}
        />
      </div>
    </div>
  );
}

export default ServicesSection;

import React from "react";
import classes from "./ReachSection.module.css";
import ReactVisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import ShopIcon from "@mui/icons-material/Shop";
import StoreIcon from "@mui/icons-material/Store";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import FlatwareIcon from "@mui/icons-material/Flatware";
import OnlineShopping from "../../../assets/icons/new/marketplace.svg";
import PublicHealth from "../../../assets/icons/new/public-health (1).svg";
import HoReCa from "../../../assets/icons/new/restaurant (2).svg";
import Retail from "../../../assets/icons/new/retail (2).svg";

function LookingFor() {
  return (
    <div id="homeSecondSection" className={classes.reachSection}>
      <div className={classes.reachContainer}>
        <h2 className={classes.smallHeader}>WHY US</h2>
        <h2 className={classes.bigHeader}>
          REACH <span>MORE CUSTOMERS </span>
          THROUGH
          <br /> OUR SALES & DISTRIBUTION CHANNELS
        </h2>
        <div className={classes.reachPoints}>
          <ReactVisibilitySensor partialVisibility offset={{ bottom: 200 }}>
            {({ isVisible }) => (
              <>
                <div className={classes.pointDiv}>
                  {/* <StoreIcon fontSize="large" /> */}
                  <img src={Retail} alt="retail" loading="lazy" />
                  <h3>Retail Stores</h3>
                  <div className={classes.stats}>
                    <h3>
                      {isVisible ? (
                        <CountUp
                          enableScrollSpy={true}
                          scrollSpyDelay={1000}
                          duration={5}
                          end={5000}
                        />
                      ) : (
                        "5000"
                      )}
                      +
                    </h3>
                  </div>
                  <div className={classes.pointText}>
                    <ul>
                      <li>Pharmacies</li>
                      <li>Discount centers</li>
                      <li>Standalone stores</li>
                      <li>Category A, B, & C retailers</li>
                    </ul>
                    {/* Category A, B, & C retailers, Discount centers, Pharmacies,
                    Standalone stores */}
                  </div>
                </div>

                <div className={classes.pointDiv}>
                  {/* <FlatwareIcon fontSize="large" /> */}
                  <img src={HoReCa} alt="HoReCa" loading="lazy" />

                  <h3>HoReCa</h3>
                  <div className={classes.stats}>
                    <h3>
                      {isVisible ? (
                        <CountUp
                          enableScrollSpy={true}
                          scrollSpyDelay={1000}
                          duration={5}
                          end={200}
                        />
                      ) : (
                        "200"
                      )}
                      +
                    </h3>
                  </div>
                  <div className={classes.pointText}>
                    <ul>
                      <li>Hotels</li>
                      <li>Cafes & Restaurants</li>
                      {/* <li>Restaurants</li> */}
                    </ul>
                  </div>
                </div>

                <div className={classes.pointDiv}>
                  {/* <ShopIcon fontSize="large" /> */}
                  <img
                    src={OnlineShopping}
                    alt="onlineShopping"
                    loading="lazy"
                  />
                  <h3>E-commerce</h3>
                  <div className={classes.stats}>
                    <h3>
                      {isVisible ? (
                        <CountUp
                          enableScrollSpy={true}
                          scrollSpyDelay={1000}
                          duration={5}
                          end={25}
                        />
                      ) : (
                        "25"
                      )}
                      +
                    </h3>
                  </div>
                  <div className={classes.pointText}>
                    <ul>
                      <li>Marketplaces</li>
                      <li>Q-commerce platforms</li>
                    </ul>
                    {/* Marketplaces, Q-commerce <br />
                    platforms */}
                  </div>
                </div>

                <div className={classes.pointDiv}>
                  {/* <Diversity2Icon fontSize="large" /> */}
                  <img src={PublicHealth} alt="others" loading="lazy" />
                  <h3>Others</h3>
                  <div className={classes.stats}>
                    <h3>
                      {isVisible ? (
                        <CountUp
                          enableScrollSpy={true}
                          scrollSpyDelay={1000}
                          duration={5}
                          end={200}
                        />
                      ) : (
                        "200"
                      )}
                      +
                    </h3>
                  </div>
                  <div className={classes.pointText}>
                    <ul>
                      <li>Schools</li>
                      <li>Caterers</li>
                      <li>Fitness centers</li>
                      <li>Hospitals & clinics</li>
                      {/* <li>and more...</li> */}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </ReactVisibilitySensor>
        </div>
      </div>
    </div>
  );
}

export default LookingFor;

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import First1 from "../../../assets/carousel/cosmetics1.avif";
// import First2 from "../../../assets/carousel/butter3.avif";
// import First3 from "../../../assets/carousel/noodles2.avif";
// import First4 from "../../../assets/carousel/Pills.avif";

// import Two1 from "../../../assets/carousel/electronics1.avif";
// import Two2 from "../../../assets/carousel/oil3.avif";
// import Two3 from "../../../assets/carousel/baby3.avif";
// import Two4 from "../../../assets/carousel/water1.avif";

import classes from "./HomeSlider.module.css";
import SliderLeft from "./SliderLeft";
import SliderRight from "./SliderRight";

// const row1 = [First1, First2, First3, First4];
// const row2 = [Two1, Two2, Two3, Two4];

// const settings1 = {
//   initialSlide: 1,
//   infinite: true,
//   slidesToShow: 2,
//   slidesToScroll: 1,
//   autoplay: true,
//   speed: 10000, //scroll speed
//   autoplaySpeed: 0, //when to start autoPlay; 0 means instantly
//   cssEase: "linear",
//   vertical: true,
//   pauseOnHover: false,
//   arrows: false,
// };

// const settings2 = {
//   initialSlide: 1,
//   infinite: true,
//   slidesToShow: 2,
//   slidesToScroll: 1,
//   autoplay: true,
//   speed: 10000,
//   autoplaySpeed: 0,
//   cssEase: "linear",
//   vertical: true,
//   centerMode: true,
//   pauseOnHover: false,
//   arrows: false,
// };

function HomeSlider() {
  const [slider, setSlider] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSlider(true);
    }, 3000);
  }, []);

  return (
    <>
      {/* {slider ? ( */}
      <div className={classes.myCarousel}>
        {/* ROW 1 */}
        <div>
          <SliderLeft />
        </div>

        {/* ROW 2 */}
        <div>
          <SliderRight />
        </div>
      </div>
      {/* ) : (
        <div className={classes.myCarouselPlaceholder}></div>
      )} */}
      {/* )} */}
    </>
  );
}

export default HomeSlider;

//import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// import First1 from "../../../assets/carousel/cosmetics1.avif";
// import First2 from "../../../assets/carousel/butter3.avif";
// import First3 from "../../../assets/carousel/noodles2.avif";
// import First4 from "../../../assets/carousel/Pills.avif";

// import Two1 from "../../../assets/carousel/electronics1.avif";
// import Two2 from "../../../assets/carousel/oil3.avif";
// import Two3 from "../../../assets/carousel/baby3.avif";
// import Two4 from "../../../assets/carousel/water1.avif";

// import classes from "./HomeSlider.module.css";

// const row1 = [First1, First2, First3, First4];
// const row2 = [Two1, Two2, Two3, Two4];

// const settings1 = {
//   initialSlide: 1,
//   infinite: true,
//   slidesToShow: 2,
//   slidesToScroll: 1,
//   autoplay: true,
//   speed: 10000, //scroll speed
//   autoplaySpeed: 0, //when to start autoPlay; 0 means instantly
//   cssEase: "linear",
//   vertical: true,
//   pauseOnHover: false,
//   arrows: false,
// };

// const settings2 = {
//   initialSlide: 1,
//   infinite: true,
//   slidesToShow: 2,
//   slidesToScroll: 1,
//   autoplay: true,
//   speed: 10000,
//   autoplaySpeed: 0,
//   cssEase: "linear",
//   vertical: true,
//   centerMode: true,
//   pauseOnHover: false,
//   arrows: false,
// };

// function HomeSlider() {
//   const [imagesLoadedRow1, setImagesLoadedRow1] = useState(
//     Array(row1.length).fill(false)
//   );

//   const [imagesLoadedRow2, setImagesLoadedRow2] = useState(
//     Array(row2.length).fill(false)
//   );

//   // const [autoplay, setAutoplay] = useState(false);

//   const handleImageLoad = (row, index) => {
//     if (row === 1) {
//       setImagesLoadedRow1((prevState) => {
//         const newState = [...prevState];
//         newState[index] = true;
//         return newState;
//       });
//     } else if (row === 2) {
//       setImagesLoadedRow2((prevState) => {
//         const newState = [...prevState];
//         newState[index] = true;
//         return newState;
//       });
//     }
//   };

//   // useEffect(() => {
//   //   if (imagesLoadedRow1.every(Boolean) && imagesLoadedRow2.every(Boolean)) {
//   //     setAutoplay(true);
//   //   }
//   // }, [imagesLoadedRow1, imagesLoadedRow2]);

//   return (
//     <>
//       <div className={classes.myCarousel}>
//         <div>
//           <Slider {...settings1} id="slider1">
//             {row1.map((imageSrc, index) => (
//               <div key={index}>
//                 <div
//                   className={classes.imageContainer}
//                   style={{
//                     backgroundColor: "red",
//                     height: "283px",
//                     width: "283px",
//                     margin: "5px",
//                     display: imagesLoadedRow1[index] ? "none" : "block",
//                   }}
//                 ></div>
//                 <img
//                   onLoad={() => handleImageLoad(1, index)}
//                   style={{
//                     height: "283px",
//                     width: "283px",
//                     margin: "5px",
//                     display: imagesLoadedRow1[index] ? "block" : "none",
//                   }}
//                   alt="img"
//                   src={imageSrc}
//                 />
//               </div>
//             ))}
//           </Slider>
//         </div>
//         <div>
//           <Slider {...settings2} id="slider2">
//             {row2.map((imageSrc, index) => (
//               <div key={index}>
//                 <div
//                   className={classes.imageContainer}
//                   style={{
//                     backgroundColor: "red",
//                     height: "283px",
//                     width: "283px",
//                     margin: "5px",
//                     display: imagesLoadedRow2[index] ? "none" : "block",
//                   }}
//                 ></div>
//                 <img
//                   onLoad={() => handleImageLoad(2, index)}
//                   style={{
//                     height: "283px",
//                     width: "283px",
//                     margin: "5px",
//                     display: imagesLoadedRow2[index] ? "block" : "none",
//                   }}
//                   alt="img"
//                   src={imageSrc}
//                 />
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>
//     </>
//   );
// }

// export default HomeSlider;

//import React, { useState } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// import First1 from "../../../assets/carousel/cosmetics1.avif";
// import First2 from "../../../assets/carousel/butter3.avif";
// import First3 from "../../../assets/carousel/noodles2.avif";
// import First4 from "../../../assets/carousel/Pills.avif";

// import Two1 from "../../../assets/carousel/electronics1.avif";
// import Two2 from "../../../assets/carousel/oil3.avif";
// import Two3 from "../../../assets/carousel/baby3.avif";
// import Two4 from "../../../assets/carousel/water1.avif";

// import classes from "./HomeSlider.module.css";

// const row1 = [First1, First2, First3, First4];
// const row2 = [Two1, Two2, Two3, Two4];

// function HomeSlider() {
//   return (
//     <>
//       <div className="container">
//         <div class="scroll-parent">
//           <div class="scroll-element primary">
//             {row1.map((img, index) => (
//               <img key={index} src={img} alt="img" />
//             ))}
//             {/* <h1>Hello</h1>
//             <h1>Hello</h1>
//             <h1>Hello</h1>
//             <h1>Hello</h1> */}
//           </div>
//           <div class="scroll-element secondary">
//             {row1.map((img, index) => (
//               <img key={index} src={img} alt="img" />
//             ))}

//             {/* <h1>Bye</h1>
//             <h1>Bye</h1>
//             <h1>Bye</h1>
//             <h1>Bye</h1> */}
//           </div>
//         </div>

//         <div class="scroll-parent">
//           <div class="scroll-element primary">
//             {/* <h1>Hello</h1>
//             <h1>Hello</h1>
//             <h1>Hello</h1>
//             <h1>Hello</h1> */}
//             {row2.map((img, index) => (
//               <img key={index} src={img} alt="img" />
//             ))}
//           </div>
//           <div class="scroll-element secondary">
//             {row2.map((img, index) => (
//               <img key={index} src={img} alt="img" />
//             ))}
//             {/* <h1>Bye</h1>
//             <h1>Bye</h1>
//             <h1>Bye</h1>
//             <h1>Bye</h1> */}
//           </div>
//         </div>
//       </div>
//       {/* )} */}
//     </>
//   );
// }

// export default HomeSlider;

import React from "react";
import Navbar from "../components/Navbar";
import HomeSection from "../components/LandingPage/HomeSection_First/HomeSection";
import ReachSection from "../components/LandingPage/ReachSection_Second/ReachSection";
import ServicesSection from "../components/LandingPage/ServicesSection_Third/ServicesSection";
import ConnectSection from "../components/LandingPage/ConnectSection_Last/ConnectSection";
import DistributorsSection from "../components/LandingPage/DistributorsSection_Fourth/DistributorsSection";
import WhatsappFloatingButton from "../components/WhatsappFloatingButtton";
import GoToTop from "../components/GoToTop";
import GeographicSection from "../components/LandingPage/GeographicSection_Fifth/GeographicSection";
import LogisticsSection from "../components/LandingPage/LogisticsSection_Sixth/LogisticsSection";
import TechnologySection from "../components/LandingPage/TechnologySection_Seventh/TechnologySection";
import { useLocation, useNavigate } from "react-router-dom";
import OurLogistics from "./OurLogistics";

function LandingPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(location.pathname);

  async function sendToSection(url, sectionId) {
    console.log(location.pathname);
    console.log(url);
    if (location.pathname !== url) {
      await navigate(url);
      const element = document.getElementById(sectionId);
      if (element) {
        // Calculate the offset by subtracting the navbar height (90px) from the scrolled element's top position
        const offset = element.offsetTop - 90;
        // 👇 Will scroll smoothly to the top of the next section
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        // Calculate the offset by subtracting the navbar height (90px) from the scrolled element's top position
        const offset = element.offsetTop - 90;
        // 👇 Will scroll smoothly to the top of the next section
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    }
  }

  return (
    <>
      <GoToTop />
      <WhatsappFloatingButton />
      <Navbar onSectionSelect={sendToSection} />
      <HomeSection />
      <ReachSection />
      <ServicesSection />
      <DistributorsSection />
      <GeographicSection />
      <LogisticsSection onSectionSelect={sendToSection} />
      <hr
        style={{
          margin: "0 auto",
          // opacity: 1,
          width: "60%",
          textAlign: "center",
          borderTop: "2px solid #0035e9",
        }}
      />
      <TechnologySection onSectionSelect={sendToSection} />
      <ConnectSection />
    </>
  );
}

export default LandingPage;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Two1 from "../../../assets/carousel/electronics1.avif";
import Two2 from "../../../assets/carousel/oil3.avif";
import Two3 from "../../../assets/carousel/baby3.avif";
import Two4 from "../../../assets/carousel/water1.avif";

const settings = {
  initialSlide: 1,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed: 10000,
  autoplaySpeed: 0,
  cssEase: "linear",
  vertical: true,
  centerMode: true,
  pauseOnHover: false,
  arrows: false,
};

function SliderRight() {
  return (
    <>
      <Slider {...settings}>
        <div>
          <img
            style={{ height: "283px", width: "283px", margin: "5px" }}
            alt="img"
            src={Two1}
          />
        </div>

        <div>
          <img
            style={{ height: "283px", width: "283px", margin: "5px" }}
            alt="img"
            src={Two2}
          />
        </div>

        <div>
          <img
            style={{ height: "283px", width: "283px", margin: "5px" }}
            alt="img"
            src={Two3}
          />
        </div>

        <div>
          <img
            style={{ height: "283px", width: "283px", margin: "5px" }}
            alt="img"
            src={Two4}
          />
        </div>
      </Slider>
    </>
  );
}

export default SliderRight;

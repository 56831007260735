import React, { useState } from "react";
import Navbar from "../components/Navbar";
import WhatsappFloatingButton from "../components/WhatsappFloatingButtton";
import classes from "./ContactUs.module.css";
import { Link } from "react-router-dom";
import GoToTop from "../components/GoToTop";
import { BASE_URL } from "../apiConfig";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactUs() {
  // const [name, setName] = useState("");
  // const [brandName, setBrandName] = useState("");
  // const [brandWebsite, setBrandWebsite] = useState("");
  // const [email, setEmail] = useState("");
  // const [contactNumber, setContactNumber] = useState("");
  // const [message, setMessage] = useState("");

  const initialFormData = {
    name: "",
    brandName: "",
    brandWebsite: "",
    email: "",
    contactNumber: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(formData);

    try {
      const response = await axios.post(
        `${BASE_URL}/send`,
        { inputData: formData },
        { withCredentials: true }
      );
      // setData(response.data.message);
      if (response.status === 200) {
        setFormData(initialFormData);
        return toast.success("Message sent successfully");
      } else {
        return toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong! Please try again later.");
    }
  }

  return (
    <>
      <GoToTop />
      <Navbar />
      <WhatsappFloatingButton />
      <ToastContainer />

      <div className={classes.supportFormDiv}>
        <h1>CONTACT US</h1>
        <form onSubmit={handleSubmit}>
          <div className={classes.supportForm}>
            {/* <div className={classes.inputGroup}> */}
            <div className={classes.formInput}>
              <label>Name*</label>
              <input
                required
                value={formData.name}
                type="text"
                name="name"
                // placeholder="Name"
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.formInput}>
              <label>Email*</label>

              <input
                required
                type="email"
                name="email"
                // placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            {/* </div> */}

            {/* <div className={classes.inputGroup}> */}
            <div className={classes.formInput}>
              <label>Brand Name*</label>

              <input
                required
                type="text"
                name="brandName"
                // placeholder="Brand Name"
                value={formData.brandName}
                onChange={handleInputChange}
              />
            </div>

            <div className={classes.formInput}>
              <label>Brand Website*</label>

              <input
                required
                type="text"
                name="brandWebsite"
                // placeholder="Brand Website"
                value={formData.brandWebsite}
                onChange={handleInputChange}
              />
            </div>
            {/* </div> */}
            <div className={classes.formInput}>
              <label>Contact number*</label>

              <input
                required
                type="tel"
                name="contactNumber"
                // placeholder="Contact Number"
                value={formData.contactNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.formInput}> </div>
            {/* <div className={classes.formInput}> */}
            <div style={{ width: "100%" }}>
              <label>Your message*</label>
              <textarea
                required
                rows={6}
                name="message"
                // placeholder="Message*"
                value={formData.message}
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.actions}>
              <button type="submit">Send message</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ContactUs;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import First1 from "../../../assets/carousel/cosmetics1.avif";
import First2 from "../../../assets/carousel/butter3.avif";
import First3 from "../../../assets/carousel/noodles2.avif";
import First4 from "../../../assets/carousel/Pills.avif";

const settings = {
  initialSlide: 1,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed: 10000, //scroll speed
  autoplaySpeed: 0, //when to start autoPlay; 0 means instantly
  cssEase: "linear",
  vertical: true,
  pauseOnHover: false,
  arrows: false,
};

function SliderLeft() {
  return (
    <>
      <Slider {...settings}>
        <div>
          <img
            style={{ height: "283px", width: "283px", margin: "5px" }}
            alt="img"
            src={First1}
          />
        </div>

        <div>
          <img
            style={{ height: "283px", width: "283px", margin: "5px" }}
            alt="img"
            src={First2}
          />
        </div>

        <div>
          <img
            style={{ height: "283px", width: "283px", margin: "5px" }}
            alt="img"
            src={First3}
          />
        </div>

        <div>
          <img
            style={{ height: "283px", width: "283px", margin: "5px" }}
            alt="img"
            src={First4}
          />
        </div>
      </Slider>
    </>
  );
}

export default SliderLeft;

import React from "react";
import classes from "./ConnectSection.module.css";

function ConnectSection() {
  return (
    <div className={classes.connectSection}>
      <h6>LET'S CONNECT</h6>
      <h3>
        LET'S GROW YOUR <br />
        CREATION, TOGETHER
      </h3>
      <div className={classes.emailForm}>
        <input type="email" name="email" placeholder="Enter Your Email ID" />
        <button>Submit</button>
      </div>
    </div>
  );
}

export default ConnectSection;
